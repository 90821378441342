import { useEffect, useState } from "react";
import adminService from "../services/admin.service";
import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Modal,
  Rating,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Dummy from "../assets/images/dummy.png";
import { colors } from "../style/colors";
import StarIcon from "@mui/icons-material/Star";
import SkipNowIcon from "../assets/images/skip-now-icon.png";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { CloseSharp } from "@mui/icons-material";

const SkipList = () => {
  const [boxDetails, setBoxDetails] = useState(false);
  const [boxDetails2, setBoxDetails2] = useState(false);
  const [allRestaurant, setAllRestaurant] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [expandedDetail, setExpandedDetail] = useState(null);
  const [showCheckoutPopup, setShowCheckoutPopup] = useState(false);
  const [amountToCharge, setAmountToCharge] = useState(0);
  const [totalAmountToCharge, setTotalAmountToCharge] = useState(0);
  const [skipFormData, setSkipFormData] = useState({
    partyName: "",
    phoneNumber: "",
    partySize: "",
  });
  const [storSkipId, setStorSkipId] = useState("");
  const [storSkipReservationId, setStorSkipReservationId] = useState("");

  const [skipErrors, setSkipErrors] = useState({});

  const [skipReservationFormData, setSkipReservationFormData] = useState({
    fullName: "",
    phoneNumber: "",
    partySize: "",
    date: null,
    time: null,
  });

  const [skipReservationerrors, setSkipReservationErrors] = useState({});

  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (expandedDetail === "skipNow") {
      setAmountToCharge(skipFormData.partySize < 5 ? 3 : 5);
    } else {
      setAmountToCharge(5);
    }
    // const stripeFee = amountToCharge * 0.029;
    // setTotalAmountToCharge(Math.round(amountToCharge + stripeFee));
  }, [expandedDetail, skipFormData.partySize, amountToCharge]);

  useEffect(() => {
    getAllRestaurant();
  }, []);
  // get Allcategory
  const getAllRestaurant = async () => {
    try {
      const response = await adminService.getRastaurant();
      if (response.status) {
        setAllRestaurant(response.data);
      }
    } catch (error) {
      console.error("Error fetching category data:", error.message);
    }
  };

  const skipReservationHandleChange = (e) => {
    const { name, value } = e.target;
    setSkipReservationFormData({
      ...skipReservationFormData,
      [name]: value,
    });

    skipNowValidation(name, value);
  };

  const skipNowValidation = (name, value, id) => {
    let tempErrors = { ...skipReservationerrors };

    switch (name) {
      case "fullName":
        tempErrors.fullName = value ? "" : "Full Name is required";
        break;
      case "phoneNumber":
        tempErrors.phoneNumber = /^[0-9\b]+$/.test(value)
          ? ""
          : "Phone number is invalid";
        break;
      case "partySize":
        tempErrors.partySize = value ? "" : "Party size is required";
        break;
      case "date":
        tempErrors.date = value ? "" : "Date is required";
        break;
      case "time":
        tempErrors.time = value ? "" : "Time is required";
        break;
      default:
        break;
    }

    setSkipReservationErrors(tempErrors);
    setSkipReservationFormData({
      ...skipReservationFormData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setSkipReservationFormData({
      ...skipReservationFormData,
      date: date,
    });

    skipNowValidation("date", date);
  };

  const handleTimeChange = (time) => {
    setSkipReservationFormData({
      ...skipReservationFormData,
      time: time,
    });

    skipNowValidation("time", time);
  };

  const skipReservationValidateForm = (id) => {
    let tempErrors = { ...skipReservationerrors };

    tempErrors.fullName = skipReservationFormData.fullName
      ? ""
      : "Full Name is required";
    tempErrors.phoneNumber = /^[0-9\b]+$/.test(
      skipReservationFormData.phoneNumber
    )
      ? ""
      : "Phone number is invalid";
    tempErrors.partySize = skipReservationFormData.partySize
      ? ""
      : "Party size is required";
    tempErrors.date = skipReservationFormData.date ? "" : "Date is required";
    tempErrors.time = skipReservationFormData.time ? "" : "Time is required";

    setSkipReservationErrors(tempErrors);

    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmitSkipReservation = (index, e, id) => {
    setStorSkipReservationId(id);
    e.preventDefault();
    const getId = allRestaurant[index].id;
    if (getId === id) {
      if (skipReservationValidateForm(id)) {
        setShowCheckoutPopup(true);
      }
    }
  };

  const skipNowhandleChange = (e) => {
    const { name, value } = e.target;
    setSkipFormData({
      ...skipFormData,
      [name]: value,
    });
    skipNowValidateFields(name, value);
  };

  const skipNowValidateFields = (name, value) => {
    let tempErrors = { ...skipErrors };

    switch (name) {
      case "partyName":
        tempErrors.partyName = value ? "" : "Party name is required";
        break;
      case "phoneNumber":
        tempErrors.phoneNumber = /^[0-9\b]+$/.test(value)
          ? ""
          : "Phone number is invalid";
        break;
      case "partySize":
        tempErrors.partySize = value ? "" : "Party size is required";
        break;
      default:
        break;
    }

    setSkipErrors(tempErrors);
  };

  const skipNowvalidateForm = () => {
    let tempErrors = {};

    tempErrors.partyName = skipFormData.partyName
      ? ""
      : "Party name is required";
    tempErrors.phoneNumber = /^[0-9\b]+$/.test(skipFormData.phoneNumber)
      ? ""
      : "Phone number is invalid";
    tempErrors.partySize = skipFormData.partySize
      ? ""
      : "Party size is required";

    setSkipErrors(tempErrors);

    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmitForm1 = (index, e, id) => {
    setStorSkipId(id);
    e.preventDefault();
    const getId = allRestaurant[index].id;
    if (getId === id) {
      if (skipNowvalidateForm()) {
        setShowCheckoutPopup(true);
      }
    }
  };

  const onCloseSkipnowPopup = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
    setExpandedDetail(
      index === expandedIndex && expandedDetail === "skipNow" ? null : "skipNow"
    );
    setSkipFormData({
      partyName: "",
      phoneNumber: "",
      partySize: "",
    });
    setSkipErrors("");
  };

  const onCloseSkipReservationPopup = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
    setExpandedDetail(
      index === expandedIndex && expandedDetail === "skipReservation"
        ? null
        : "skipReservation"
    );
    setSkipReservationFormData({
      fullName: "",
      phoneNumber: "",
      partySize: "",
      date: null,
      time: null,
    });
    setSkipReservationErrors("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setPaymentProcessing(true);

    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const body = new URLSearchParams({
      amount: amountToCharge * 100,
      currency: "usd",
      "payment_method_types[]": "card",
    }).toString();

    const response = await fetch("https://api.stripe.com/v1/payment_intents", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
      },
      body: body,
    });

    const paymentIntent = await response.json();

    const { error, paymentIntent: confirmedPaymentIntent } =
      await stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: {
          card: cardElement,
        },
      });

    if (error) {
      setErrorMessage(error.message);
      setPaymentProcessing(false);
    } else if (confirmedPaymentIntent.status === "succeeded") {
      setPaymentProcessing(false);
      setShowCheckoutPopup(false);
      if (expandedDetail === "skipNow") {
        onCloseSkipnowPopup(expandedIndex);
      } else {
        onCloseSkipReservationPopup(expandedIndex);
      }
      setOpen(true);
      const apiData = {
        restaurant_id:
          expandedDetail === "skipNow" ? storSkipId : storSkipReservationId,
        party_name:
          expandedDetail === "skipNow"
            ? skipFormData.partyName
            : skipReservationFormData.fullName,
        size:
          expandedDetail === "skipNow"
            ? skipFormData.partySize
            : skipReservationFormData.partySize,
        phone:
          expandedDetail === "skipNow"
            ? skipFormData.phoneNumber
            : skipReservationFormData.phoneNumber,
        date:
          expandedDetail === "skipNow"
            ? ""
            : skipReservationFormData.date.format("YYYY-MM-DD"),
        time:
          expandedDetail === "skipNow"
            ? ""
            : skipReservationFormData.time.format("HH:mm"),
        transaction_id: confirmedPaymentIntent.id,
        amount: amountToCharge,
        pay_type: expandedDetail === "skipNow" ? 0 : 1,
      };
      try {
        const response = adminService.skipPayment(apiData);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleClose = () => {
    setShowCheckoutPopup(false);
    setOpen(false);
    setErrorMessage("");
  };
  return (
    <>
      <Container maxWidth="xl">
        <Grid container>
          <Grid
            item
            md="8"
            xs="12"
            sx={{
              display: "flex",
              gap: "16px",
              flexDirection: "column",
            }}
          >
            {allRestaurant !== undefined &&
              allRestaurant !== null &&
              allRestaurant.length >= 0 &&
              allRestaurant?.map((item, index) => {
                return (
                  <Box
                    sx={{
                      ":hover": {
                        border: "1px solid #0D0D0D",
                      },
                      transition: "all .5s ease",
                      border: `1px solid ${
                        boxDetails || boxDetails2 ? "#0D0D0D" : "#0000001A"
                      } `,
                      borderRadius: "16px",
                      padding: "12px",
                    }}
                    key={index}
                  >
                    <Grid container spacing={"24px"}>
                      <Grid
                        item
                        sm={3}
                        xs={12}
                        sx={{
                          "& img": {
                            width: "100%",
                            borderRadius: "8px",
                            objectFit: "cover",
                          },
                        }}
                      >
                        <img src={Dummy} alt="" />
                      </Grid>

                      <Grid
                        item
                        lg={4}
                        sx={{
                          display: "flex",
                          gap: "6px",
                          flexDirection: "column",
                        }}
                      >
                        <Box sx={{ pb: "3px" }}>
                          <Stack direction="row" spacing={1}>
                            <Chip
                              label="LIVE"
                              sx={{
                                bgcolor: colors.themeText.TextLink,
                                color: "#FFF",
                                height: "26px",
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "18px",
                              }}
                            />
                            <Chip
                              label={`${item.waittime}m waiting`}
                              sx={{
                                bgcolor: colors.themeBg.bgPrimary,
                                color: "#0D0D0D",
                                height: "26px",
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "18px",
                              }}
                            />
                          </Stack>
                        </Box>
                        <Typography
                          variant="h4"
                          color={colors.themeText.textBlack}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          variant="body1"
                          color={colors.themeText.TextGray}
                        >
                          {item.address1}
                        </Typography>
                        <Grid
                          container
                          sx={{ gap: "4px", alignItems: "center" }}
                        >
                          <Typography
                            sx={{
                              color: "#FFB829",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            {` ${item.rating}/5`}
                          </Typography>
                          <Typography
                            sx={{ display: "flex" }}
                            variant="body1"
                            color={colors.themeText.TextGray}
                          >
                            <Rating
                              name="text-feedback"
                              size="small"
                              value={item.rating}
                              readOnly
                              precision={0.5}
                              emptyIcon={
                                <StarIcon
                                  style={{ opacity: 0.55 }}
                                  fontSize="inherit"
                                />
                              }
                            />
                            {/* <Rating
                                name="read-only"
                                size="small"
                                value={item.rating}
                                readOnly
                              /> */}
                          </Typography>
                          <Typography
                            color={colors.themeText.TextGray}
                            sx={{ fontSize: "10px", lineHeight: "15px" }}
                          >
                            (230 reviews)
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* <Grid item  container spacing={'8px'} lg={5} sx={{  alignItems: "center", gap: "8px", justifyContent: "flex-end" }}> */}

                      <Grid
                        item
                        lg={5}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => onCloseSkipnowPopup(index)}
                          sx={{
                            ":hover": {
                              bgcolor: "#E4E4E4",
                            },
                            border: "1px solid #0D0D0D",
                            minWidth: "32px",
                            fontSize: "14px",
                            textTransform: "inherit",
                            padding: "3px 16px",
                            color:
                              expandedIndex === index &&
                              expandedDetail === "skipNow"
                                ? "#FFF !important"
                                : "#000",
                            background:
                              expandedIndex === index &&
                              expandedDetail === "skipNow"
                                ? "#0D0D0D !important"
                                : "#FFF",
                          }}
                        >
                          <img
                            src={SkipNowIcon}
                            width="20"
                            height="20"
                            alt=""
                            style={{ marginLeft: "-4px", marginRight: "4px" }}
                          />
                          Skip now
                        </Button>

                        <Button
                          onClick={() => onCloseSkipReservationPopup(index)}
                          variant="contained"
                          sx={{
                            fontSize: "14px",
                            textTransform: "inherit",
                            padding: "4px 16px",
                            background:
                              expandedIndex === index &&
                              expandedDetail === "skipReservation"
                                ? "#40B7AE !important"
                                : "",
                          }}
                        >
                          Skip reservation
                        </Button>
                      </Grid>
                    </Grid>

                    {expandedIndex === index &&
                      expandedDetail === "skipNow" && (
                        <Box
                          sx={{
                            display: "flex",
                            borderTop: "1px solid #0000001A",
                            marginTop: "12px",
                            paddingTop: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              background: "#F7F7F7",
                              borderRadius: "8px",
                              display: "flex",
                              gap: "8px",
                              width: "100%",
                              flexDirection: "column",
                              padding: "12px 16px 16px 16px",
                            }}
                            component="form"
                            onSubmit={(e) => {
                              handleSubmitForm1(index, e, item.id);
                            }}
                          >
                            <Typography variant="h6" sx={{ fontWeight: "700" }}>
                              Enter party details
                            </Typography>

                            <Grid container spacing={"12px"}>
                              <Grid item sm={3} xs={12}>
                                <FormControl
                                  error={!!skipErrors.partyName}
                                  sx={{ width: "100%" }}
                                >
                                  <TextField
                                    placeholder="Party name"
                                    name="partyName"
                                    value={skipFormData.partyName}
                                    onChange={skipNowhandleChange}
                                    sx={{
                                      backgroundColor: "#FFF",
                                      borderRadius: "30px",
                                      color: "#0D0D0D",
                                      width: "100%",
                                      fontSize: "14px",
                                      "& input": {
                                        py: { md: "14px", xs: "8px" },
                                        paddingX: (theme) => theme.spacing(2),
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      },
                                      "& fieldset": {
                                        borderRadius: "30px",
                                        border: `${
                                          skipErrors.partyName
                                            ? "1px solid red"
                                            : "1px solid #00000030"
                                        }`,
                                        color: "#0D0D0D",
                                        fontSize: "14px",
                                      },
                                    }}
                                  />
                                  {skipErrors.partyName && (
                                    <FormHelperText>
                                      {skipErrors.partyName}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid item sm={3} xs={12}>
                                <FormControl
                                  error={!!skipErrors.phoneNumber}
                                  sx={{ width: "100%" }}
                                >
                                  <TextField
                                    id={item.id}
                                    placeholder="Phone number"
                                    name="phoneNumber"
                                    value={skipFormData.phoneNumber}
                                    onChange={skipNowhandleChange}
                                    sx={{
                                      backgroundColor: "#FFF",
                                      borderRadius: "30px",
                                      color: "#0D0D0D",
                                      width: "100%",
                                      fontSize: "14px",
                                      "& input": {
                                        py: { md: "14px", xs: "8px" },
                                        paddingX: (theme) => theme.spacing(2),
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      },
                                      "& fieldset": {
                                        borderRadius: "30px",
                                        border: `${
                                          skipErrors.phoneNumber
                                            ? "1px solid red"
                                            : "1px solid #00000030"
                                        }`,
                                        color: "#0D0D0D",
                                        fontSize: "14px",
                                      },
                                    }}
                                  />
                                  {skipErrors.phoneNumber && (
                                    <FormHelperText>
                                      {skipErrors.phoneNumber}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid item sm={3} xs={12}>
                                <FormControl
                                  error={!!skipErrors.partySize}
                                  sx={{ width: "100%" }}
                                >
                                  <Select
                                    id={item.id}
                                    name="partySize"
                                    value={skipFormData.partySize}
                                    onChange={skipNowhandleChange}
                                    displayEmpty
                                    sx={{
                                      backgroundColor: "#FFF",
                                      borderRadius: "30px",
                                      color: "#959595",
                                      width: "100%",
                                      fontSize: "14px",
                                      "& .MuiSelect-select": {
                                        py: { md: "14px", xs: "8px" },
                                      },
                                      "& fieldset": {
                                        borderRadius: "30px",
                                        border: "1px solid #00000030",
                                        fontSize: "14px",
                                      },
                                    }}
                                  >
                                    <MenuItem value="">Party Size</MenuItem>
                                    <MenuItem value="1">Party Size 1</MenuItem>
                                    <MenuItem value="2">Party Size 2</MenuItem>
                                    <MenuItem value="3">Party Size 3</MenuItem>
                                    <MenuItem value="4">Party Size 4</MenuItem>
                                    <MenuItem value="5">Party Size 5</MenuItem>
                                    <MenuItem value="6">Party Size 6</MenuItem>
                                    <MenuItem value="7">Party Size 7</MenuItem>
                                    <MenuItem value="8">Party Size 8</MenuItem>
                                    <MenuItem value="9">Party Size 9</MenuItem>
                                    <MenuItem value="10">
                                      Party Size 10
                                    </MenuItem>
                                  </Select>
                                  {skipErrors.partySize && (
                                    <FormHelperText>
                                      {skipErrors.partySize}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid item lg={3} ml="auto">
                                <Button
                                  type="submit"
                                  sx={{
                                    ":hover": {
                                      bgcolor: colors.themeBg.bgPrimary,
                                      color: colors.themeText.textBlack,
                                    },
                                    bgcolor: colors.themeBg.bgBlack,
                                    color: colors.themeBg.bgWhite,
                                    fontSize: "16px",
                                    padding: "12px 20px",
                                    width: "100%",
                                  }}
                                >
                                  Skip And Pay
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      )}

                    {expandedIndex === index &&
                      expandedDetail === "skipReservation" && (
                        <Box
                          sx={{
                            display: "flex",
                            borderTop: "1px solid #0000001A",
                            marginTop: "12px",
                            paddingTop: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              background: "#F7F7F7",
                              borderRadius: "8px",
                              display: "flex",
                              gap: "8px",
                              width: "100%",
                              flexDirection: "column",
                              padding: "12px 16px 16px 16px",
                            }}
                            component="form"
                            onSubmit={(e) => {
                              handleSubmitSkipReservation(index, e, item.id);
                            }}
                          >
                            <Typography variant="h6" sx={{ fontWeight: "700" }}>
                              Enter party details
                            </Typography>

                            <Grid container spacing={"12px"}>
                              <Grid item sm={4} xs={12}>
                                <FormControl
                                  error={!!skipReservationerrors.phoneNumber}
                                  sx={{ width: "100%" }}
                                >
                                  <TextField
                                    placeholder="Full Name"
                                    name="fullName"
                                    value={skipReservationFormData.fullName}
                                    onChange={skipReservationHandleChange}
                                    sx={{
                                      backgroundColor: "#FFF",
                                      borderRadius: "30px",
                                      color: "#0D0D0D",
                                      width: "100%",
                                      fontSize: "14px",
                                      "& input": {
                                        py: { md: "14px", xs: "8px" },
                                        paddingX: (theme) => theme.spacing(2),
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      },
                                      "& fieldset": {
                                        borderRadius: "30px",
                                        border: `${
                                          skipReservationerrors.fullName
                                            ? "1px solid red"
                                            : "1px solid #00000030"
                                        }`,
                                        color: "#0D0D0D",
                                        fontSize: "14px",
                                      },
                                    }}
                                  />
                                  {skipReservationerrors.fullName && (
                                    <FormHelperText>
                                      {skipReservationerrors.fullName}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid item sm={4} xs={12}>
                                <FormControl
                                  error={!!skipReservationerrors.phoneNumber}
                                  sx={{ width: "100%" }}
                                >
                                  <TextField
                                    placeholder="Phone number"
                                    name="phoneNumber"
                                    value={skipReservationFormData.phoneNumber}
                                    onChange={skipReservationHandleChange}
                                    sx={{
                                      backgroundColor: "#FFF",
                                      borderRadius: "30px",
                                      color: "#0D0D0D",
                                      width: "100%",
                                      fontSize: "14px",
                                      "& input": {
                                        py: { md: "14px", xs: "8px" },
                                        paddingX: (theme) => theme.spacing(2),
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      },
                                      "& fieldset": {
                                        borderRadius: "30px",
                                        border: `${
                                          skipReservationerrors.phoneNumber
                                            ? "1px solid red"
                                            : "1px solid #00000030"
                                        }`,
                                        color: "#0D0D0D",
                                        fontSize: "14px",
                                      },
                                    }}
                                  />
                                  {skipReservationerrors.phoneNumber && (
                                    <FormHelperText>
                                      {skipReservationerrors.phoneNumber}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid item sm={4} xs={12}>
                                <FormControl
                                  error={!!skipReservationerrors.partySize}
                                  sx={{ width: "100%" }}
                                >
                                  <Select
                                    name="partySize"
                                    value={skipReservationFormData.partySize}
                                    onChange={skipReservationHandleChange}
                                    displayEmpty
                                    sx={{
                                      backgroundColor: "#FFF",
                                      borderRadius: "30px",
                                      color: "#959595",
                                      width: "100%",
                                      fontSize: "14px",
                                      "& .MuiSelect-select": {
                                        py: { md: "14px", xs: "8px" },
                                      },
                                      "& fieldset": {
                                        borderRadius: "30px",
                                        border: "1px solid #00000030",
                                        fontSize: "14px",
                                      },
                                    }}
                                  >
                                    <MenuItem value="">Party Size</MenuItem>
                                    <MenuItem value="1">Party Size 1</MenuItem>
                                    <MenuItem value="2">Party Size 2</MenuItem>
                                    <MenuItem value="3">Party Size 3</MenuItem>
                                    <MenuItem value="4">Party Size 4</MenuItem>
                                    <MenuItem value="5">Party Size 5</MenuItem>
                                    <MenuItem value="6">Party Size 6</MenuItem>
                                    <MenuItem value="7">Party Size 7</MenuItem>
                                    <MenuItem value="8">Party Size 8</MenuItem>
                                    <MenuItem value="T9">Party Size 9</MenuItem>
                                    <MenuItem value="10">
                                      Party Size 10
                                    </MenuItem>
                                  </Select>
                                  {skipReservationerrors.partySize && (
                                    <FormHelperText>
                                      {skipReservationerrors.partySize}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid item sm={4} xs={12}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    sx={{
                                      border: `${
                                        skipReservationerrors.date
                                          ? "1px solid red"
                                          : "1px solid #00000030"
                                      }`,
                                    }}
                                    value={skipReservationFormData.date}
                                    onChange={handleDateChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        error={!!skipReservationerrors.date}
                                      />
                                    )}
                                  />
                                  {skipReservationerrors.date && (
                                    <FormHelperText error>
                                      {skipReservationerrors.date}
                                    </FormHelperText>
                                  )}
                                </LocalizationProvider>
                              </Grid>

                              <Grid item sm={4} xs={12}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    ampm={false}
                                    sx={{
                                      borderRadius: "4px",
                                      border: `${
                                        skipReservationerrors.time
                                          ? "1px solid red"
                                          : "1px solid #00000030"
                                      }`,
                                    }}
                                    value={skipReservationFormData.time}
                                    onChange={handleTimeChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        error={!!skipReservationerrors.time}
                                      />
                                    )}
                                  />
                                  {skipReservationerrors.time && (
                                    <FormHelperText error>
                                      {skipReservationerrors.time}
                                    </FormHelperText>
                                  )}
                                </LocalizationProvider>
                              </Grid>
                              <Grid item lg={3} ml="auto">
                                <Button
                                  type="submit"
                                  sx={{
                                    ":hover": {
                                      bgcolor: colors.themeBg.bgPrimary,
                                      color: colors.themeText.textBlack,
                                    },
                                    bgcolor: colors.themeBg.bgBlack,
                                    color: colors.themeBg.bgWhite,
                                    fontSize: "16px",
                                    padding: "12px 20px",
                                    width: "100%",
                                  }}
                                >
                                  Skip And Pay
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      )}
                  </Box>
                );
              })}
          </Grid>
          <Grid
            item
            md="4"
            xs="12"
            pl={{ md: "55px" }}
            pt={{ xs: "30px", md: 0 }}
          >
            <Box
              sx={{
                borderRadius: "16px",
                boxShadow: "0px 3px 15px #ebebeb",
                position: "sticky",
                top: "50px",
              }}
              p="16px 24px"
            >
              <Typography
                variant="h4"
                pb="24px"
                color={colors.themeText.textBlack}
              >
                Notes from the Business
              </Typography>
              <Typography
                variant="body1420"
                pb="16px"
                display="block"
                color={colors.themeText.textBlack2}
              >
                Currently serving below restaurants only
              </Typography>
              <Typography
                variant="body1420"
                pb="16px"
                display="block"
                color={colors.themeText.textBlack2}
              >
                <b>$3</b> per skip except when party{" "}
                <b>size 5 or more then 5</b>
              </Typography>
              <Typography
                variant="body1420"
                pb="16px"
                display="block"
                color={colors.themeText.textBlack2}
              >
                Serving only restaurants that <b>DO NOT</b> take reservations
              </Typography>
              <Typography
                variant="body1420"
                pb="16px"
                display="block"
                color={colors.themeText.textBlack2}
              >
                <b>Step 2:</b> Enter Party details into fields
              </Typography>
              <Typography
                variant="body1420"
                pb="16px"
                display="block"
                color={colors.themeText.textBlack2}
              >
                <b>Step 3:</b> Pay and be added to the live wait list!
              </Typography>
              <Typography
                variant="body1420"
                pb="16px"
                display="block"
                color={colors.themeText.textBlack2}
              >
                You will receive picture confirmation via text of your added
                party
              </Typography>
            </Box>
          </Grid>

          <Modal
            open={showCheckoutPopup}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="checkout-main"
          >
            <Box
              p={{ xs: "40px", sm: "72px" }}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                outline: "none",
                borderRadius: "16px",
                width: "90%",
                maxWidth: 512,
                boxSizing: "border-box",
                bgcolor: "background.paper",
              }}
            >
              <Box onClick={handleClose}>
                <CloseSharp
                  style={{
                    color: "#C3C3C3",
                    fontSize: 28,
                    position: "absolute",
                    right: "20",
                    top: "20",
                    cursor: "pointer",
                  }}
                />
              </Box>
              <Typography
                variant="h1"
                pb={4}
                color={colors.themeText.textBlack}
                textAlign="center"
              >
                Checkout
              </Typography>
              {expandedDetail === "skipNow" ? (
                <Typography
                  variant="body1"
                  pb="16px"
                  color={colors.themeText.textBlack2}
                >
                  <ul>
                    <li>
                      <strong>Total Payable Amount:</strong>{" "}
                      {`$${amountToCharge}.00`}
                    </li>
                    {/* <li>
                      <strong>Stripe Fee:</strong>{" "}
                      {`${Math.round(amountToCharge * 0.029)}`}
                    </li>
                    <li>
                      <strong>Total Payable Amount:</strong>{" "}
                      {`${Math.round(totalAmountToCharge)}`}
                    </li> */}
                  </ul>
                </Typography>
              ) : (
                <Typography
                  variant="body1"
                  pb="16px"
                  color={colors.themeText.textBlack2}
                >
                  <ul>
                    <li>
                      <strong>Amount:</strong> $5.00
                    </li>
                    {/* <li>
                      <strong>Stripe Fee:</strong>{" "}
                      {`${Math.round(500 * 0.029)} cents`}
                    </li>
                    <li>
                      <strong>Total Payable Amount:</strong>{" "}
                      {`${Math.round(500 + 500 * 0.029)} cents`}
                    </li> */}
                  </ul>
                </Typography>
              )}
              <form onSubmit={handleSubmit}>
                <div className="checkout-pay-box">
                  <CardElement />
                </div>
                {errorMessage && (
                  <div className="stripeError">{errorMessage}</div>
                )}
                <Box textAlign="center" pt={4}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ px: 3, py: 1.5 }}
                    disabled={!stripe || paymentProcessing}
                  >
                    {paymentProcessing ? "Processing..." : "Pay"}
                  </Button>
                </Box>
              </form>
            </Box>
          </Modal>
        </Grid>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          p={{ xs: "40px", sm: "72px" }}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            outline: "none",
            borderRadius: "16px",
            width: "90%",
            maxWidth: 512,
            boxSizing: "border-box",
            bgcolor: "background.paper",
          }}
        >
          <Box onClick={handleClose}>
            <CloseSharp
              style={{
                color: "#C3C3C3",
                fontSize: 28,
                position: "absolute",
                right: "20",
                top: "20",
                cursor: "pointer",
              }}
            />
          </Box>
          <Typography
            variant="h1"
            pb={4}
            color={colors.themeText.textBlack}
            textAlign="center"
          >
            Thank you
          </Typography>
          <Typography
            variant="h6"
            fontWeight="normal"
            color={colors.themeText.textBlack}
            textAlign="center"
          >
            You have successfully skip the waiting.
          </Typography>
          <Box textAlign="center" pt={4}>
            <Button variant="contained" sx={{ px: 3, py: 1.5 }} href="/">
              Back to listing page
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
const CheckoutPopup = ({ children, onClose }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1,
        bgcolor: "background.paper",
        border: "1px solid #000",
        boxShadow: 24,
        p: 4,
      }}
    >
      {children}
      <Button
        sx={{
          position: "absolute",
          top: "10px",
          right: "10px",
          bgcolor: "transparent",
          border: "none",
          padding: "0",
          cursor: "pointer",
        }}
        onClick={onClose}
      >
        ×
      </Button>
    </Box>
  );
};

export default SkipList;
