import axios from "axios";
// const API_URL = 'http://157.245.107.181:1324/api'
const API_URL = "https://skipapp.com/api";

// Get Restaurant List
const getRastaurant = (data) => {
  return axios.get(`${API_URL}/restaurant`, data);
};

// Restaurant & email collect
const addrestaurantandemail = (data) => {
  return axios.post(`${API_URL}/collect`, data);
};
// Restaurant & email collect
const skipPayment = (data) => {
  return axios.post(`${API_URL}/skip/pays`, data);
};

export default {
  getRastaurant,
  addrestaurantandemail,
  skipPayment,
};
