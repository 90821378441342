import React, { useEffect, useState } from "react";
import { CloseSharp } from "@mui/icons-material";
import {
  Grid,
  Typography,
  Button,
  Box,
  Container,
  Backdrop,
  Modal,
} from "@mui/material";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { colors } from "../style/colors";
import HeroBanner from "../assets/images/HeroBanner.png";
// Icons
import adminService from "../services/admin.service";
import Footer from "../Components/Footer";
import SkipList from "../Components/SkipList";

const BusinessCard = () => {
  return (
    <>
      {/* Hero section */}
      <Box
        component="section"
        sx={{
          bgcolor: colors.themeBg.bgBlack,
          position: "relative",
          backgroundImage: `url('${HeroBanner}')`,
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container sx={{ position: "relative", zIndex: 1 }} maxWidth="xl">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            py={{ sm: 3, xs: 1 }}
          >
            <Grid item xs="auto">
              <Link href="#">
                <Logo />
              </Link>
            </Grid>
            <Grid item xs="auto">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="buttonGhostTonal"
                  sx={{ px: 3, py: 1.5, mr: { md: 3, xs: 1 } }}
                >
                  Request Restaurant{" "}
                </Button>
                <Button variant="contained" sx={{ px: 3, py: 1.5 }}>
                  Become lister
                </Button>
              </Box>
            </Grid>
          </Grid>

          {/* Content */}
          <Grid
            container
            justifyContent="center"
            pt={{ sm: 7, xs: 4 }}
            pb={{ sm: 10, xs: 6 }}
          >
            <Grid item xs="12" md="6">
              <Typography
                variant="h1"
                mb={2}
                color={colors.themeText.TextWhite}
                textAlign="center"
              >
                Skip the waiting line with waiting buddy
              </Typography>
              <Typography
                variant="body2"
                fontWeight="normal"
                color={colors.themeText.TextWhite}
                textAlign="center"
                sx={{ opacity: 0.9 }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard text ever
                since the 1500s.
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Backdrop
          open
          sx={{
            position: "absolute",
            inset: 0,
            bgcolor: colors.themeBg.bgBlack,
            opacity: "0.9 !important",
            filter: "blur(216)",
          }}
        ></Backdrop>
      </Box>

      {/* Explore Restaurants */}

      <Box component="section" sx={{ py: { md: 10, xs: 6 } }}>
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xs="12" md="7" sx={{ pb: { md: 6, xs: 4 } }}>
              <Typography
                variant="h2"
                pb="12px"
                color={colors.themeText.textBlack}
              >
                Explore Restaurants
              </Typography>
              <Typography
                variant="body1"
                pr="24px"
                color={colors.themeText.TextGray}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard text ever
                since the 1500s.
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <SkipList />
      </Box>

      {/* Footer */}
      <Footer />
      <Box
        component="section"
        sx={{ bgcolor: colors.themeBg.bgBlack, py: 2 }}
        color={colors.themeText.TextWhite}
        textAlign="center"
      >
        <Typography
          variant="h6"
          fontWeight="normal"
          color={colors.themeText.TextWhite}
          textAlign="center"
        >
          Contact us on : Support@skipapp.com
        </Typography>
      </Box>     
    </>
  );
};
export default BusinessCard;
