localStorage.setItem("mode", "dark");
const mode = localStorage?.getItem("mode");

export const colors = {
  basics: {
    // theme: `linear-gradient(180.53deg, #A175FF 7.8%, #563AFF 119.47%)`,
    theme: mode === "dark" ? `#000` : "#000",
  },
  themeText: {
    textBlack: "#0B0B0B",
    TextWhite: "#FFFFFF",
    TextGray: "#555555",
    TextYellow: "#FFB829",
    TextLink: "#E24A57",
    textBlack2: "#040404",
  },
  themeBg: {
    bgWhite: "#FFFFFF",
    bgPrimary: "#6BE4DB",
    bgPrimaryHover: "#a8fff9",
    bgBlack: "#0B0B0B",
    bgGray: "#F7F7F7",
  },
  border: {
    borderGray: '#E5E5E5',
    borderBlack: "#0B0B0B",
  },
  table: {
    theadBg: "#dbdbdb",
  },
  input: {
    bg: "#dfdfdf",
    border: `1px solid #ddd`,
    white: "#fff",
  },
  dark: {
    800: "#ff0000",
    600: "#1D1D1D",
    200: "#343434",
    100: "#393939",
  },
  gray: {
    500: "#7D7D7D",
    400: "#D5D5D5",
    300: "#A9A9A9",
    200: "#E9E9E9",
  },
};
