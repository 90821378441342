import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe("pk_test_51Kg0yPEHZfpJ4bExE8JBpuCl1bwE0RFLVGqYEF2HvK4MT6lMP5Xka9MytUoZ66orTeepF7cQgcNssmvfiwvxO3tD00xrDp1f2U");

const StripeProvider = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeProvider;
