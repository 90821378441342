import React from "react";
import "../src/App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BusinessCard from "./Pages/Index";
import StripeProvider from "./StripeContext";
const App = () => {
  return (
    <>
      <StripeProvider>
        <Router>
          <Routes>
            <Route path="/" element={<BusinessCard />} />
            {/* <Route path="/thanks" element={<Inde />} /> */}

          </Routes>
        </Router>
      </StripeProvider>
    </>
  );
};

export default App;
